import React from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

const DarkModeToggle = ({ onChange, style }) => (
  <ThemeToggler>
    {({ theme, toggleTheme }) => {
      const isDarkMode = theme === "dark"
      const title = `Active ${isDarkMode ? "light" : "dark"} mode`
      const size = 32

      const handleChange = event => {
        const currentTheme = event.target.checked ? "dark" : "light"
        toggleTheme(currentTheme)
      }

      return (
        <label style={{ cursor: "pointer", ...style }}>
          <input
            style={{ display: "none" }}
            type="checkbox"
            onChange={handleChange}
            checked={isDarkMode}
          />{" "}
          {isDarkMode ? (
            // <!-- Generated by IcoMoon.io -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={size}
              height={size}
              viewBox="0 0 32 32"
            >
              <title>{title}</title>
              <path
                fill="currentColor"
                d="M16 9c-3.859 0-7 3.141-7 7s3.141 7 7 7 7-3.141 7-7c0-3.859-3.141-7-7-7zM16 7c0.552 0 1-0.447 1-1v-2c0-0.552-0.448-1-1-1-0.553 0-1 0.448-1 1v2c0 0.553 0.447 1 1 1zM16 25c-0.553 0-1 0.448-1 1v2c0 0.553 0.447 1 1 1 0.552 0 1-0.447 1-1v-2c0-0.552-0.448-1-1-1zM23.776 9.635l1.414-1.414c0.391-0.391 0.391-1.023 0-1.414s-1.023-0.391-1.414 0l-1.414 1.414c-0.391 0.391-0.391 1.023 0 1.414s1.023 0.391 1.414 0zM8.221 22.366l-1.414 1.414c-0.391 0.391-0.391 1.023 0 1.414s1.023 0.391 1.414 0l1.414-1.414c0.391-0.393 0.391-1.023 0-1.414s-1.023-0.393-1.414 0zM7 16c0-0.552-0.448-1-1-1h-2c-0.553 0-1 0.448-1 1 0 0.553 0.447 1 1 1h2c0.552 0 1-0.447 1-1zM28 15h-2c-0.553 0-1 0.448-1 1 0 0.553 0.447 1 1 1h2c0.552 0 1-0.447 1-1 0-0.552-0.448-1-1-1zM8.22 9.635c0.391 0.391 1.023 0.391 1.414 0s0.391-1.023 0-1.414l-1.414-1.414c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.023 0 1.414l1.414 1.414zM23.779 22.363c-0.393-0.391-1.023-0.391-1.414 0s-0.393 1.023 0 1.414l1.414 1.414c0.391 0.391 1.023 0.391 1.414 0s0.391-1.023 0-1.414l-1.414-1.414z"
              ></path>
            </svg>
          ) : (
            // <!-- Generated by IcoMoon.io -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={size}
              height={size}
              viewBox="0 0 32 32"
            >
              <title>{title}</title>
              <path
                fill="currentColor"
                d="M10.895 7.574c0 7.55 5.179 13.67 11.567 13.67 1.588 0 3.101-0.38 4.479-1.063-1.695 4.46-5.996 7.636-11.051 7.636-6.533 0-11.83-5.297-11.83-11.83 0-4.82 2.888-8.959 7.023-10.803-0.116 0.778-0.188 1.573-0.188 2.39z"
              ></path>
            </svg>
          )}
        </label>
      )
    }}
  </ThemeToggler>
)

export default DarkModeToggle
